import axios from 'axios'; // Import axios
import dayjs from 'dayjs'; // For formatting date and time
export default {
  state: {
    settingToggle: false,
    openinfo: true,
    activechat: false,
    searchContact: "",
    mobileChatSidebar: false,
    profileinfo: {},
    messFeed: [],
    user: {}, 
    contacts: [
      {
        id: 1,
        fullName: "Kathryn Murphy",
        role: "Frontend Developer",
        lastmessage: "Hey! there I'm available",
        lastmessageTime: "2:30 PM",
        unredmessage: Math.floor(Math.random() * 10),
        avatar: require("@/assets/images/users/user-2.jpg"),
        status: "offline",
      },
      {
        id: 2,
        fullName: "Felecia Rower",
        role: " UI/UX Designer",
        lastmessage: "Hey! there I'm available",
        lastmessageTime: "2:30 PM",
        unredmessage: Math.floor(Math.random() * 10),
        avatar: require("@/assets/images/users/user-3.jpg"),
        status: "active",
      },
      {
        id: 3,
        fullName: " Aileen Chavez",
        role: " Backend Developer",
        lastmessage: "Hey! there I'm available",
        lastmessageTime: "2:30 PM",
        unredmessage: Math.floor(Math.random() * 10),
        avatar: require("@/assets/images/users/user-4.jpg"),
        status: "offline",
      },
      {
        id: 4,
        fullName: "Alec Thompson",
        role: " Full Stack Developer",
        lastmessage: "Hey! there I'm available",
        lastmessageTime: "2:30 PM",
        unredmessage: Math.floor(Math.random() * 10),
        avatar: require("@/assets/images/users/user-5.jpg"),
        status: "active",
      },
      {
        id: 5,
        fullName: "Murphy Aileen",
        role: "Frontend Developer",
        lastmessage: "Hey! there I'm available",
        lastmessageTime: "2:30 PM",
        unredmessage: Math.floor(Math.random() * 10),
        avatar: require("@/assets/images/users/user-6.jpg"),
        status: "offline",
      },
    ],
    chats: [
      {
        id: 1,
        userId: 1,
        messages: [
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Hey! How are you?",
            time: "10:00",
            sender: "them",
          },
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Good, I will book the meeting room for you.",
            time: "10:02",

            sender: "them",
          },
          {
            content: "Hi, I am good, what about you?",
            img: require("@/assets/images/users/user-1.jpg"),
            time: "10:01",
            sender: "me",
          },

          {
            content: "Thanks, It will be great.",
            img: require("@/assets/images/users/user-1.jpg"),
            time: "10:03",
            sender: "me",
          },
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Hey! How are you?",
            time: "10:00",
            sender: "them",
          },
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Good, I will book the meeting room for you.",
            time: "10:02",

            sender: "them",
          },
          {
            content: "Hi, I am good, what about you?",
            img: require("@/assets/images/users/user-1.jpg"),
            time: "10:01",
            sender: "me",
          },

          {
            content: "Thanks, It will be great.",
            img: require("@/assets/images/users/user-1.jpg"),
            time: "10:03",
            sender: "me",
          },
        ],
      },
      {
        id: 2,
        userId: 2,
        messages: [
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Hey! How are you?",
            time: "10:00",
            sender: "them",
          },
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Good, I will book the meeting room for you.",
            time: "10:02",

            sender: "them",
          },
        ],
      },
      {
        id: 3,
        userId: 3,
        messages: [
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Hey! How are you?",
            time: "10:00",
            sender: "them",
          },
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Good, I will book the meeting room for you.",
            time: "10:02",

            sender: "me",
          },
        ],
      },
      {
        id: 4,
        userId: 4,
        messages: [
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Hey! How are you?",
            time: "10:00",
            sender: "me",
          },
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Good, I will book the meeting room for you.",
            time: "10:02",

            sender: "them",
          },
        ],
      },
      {
        id: 5,
        userId: 5,
        messages: [
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Hey! How are you?",
            time: "10:00",
            sender: "them",
          },
          {
            img: require("@/assets/images/users/user-2.jpg"),
            content: "Good, I will book the meeting room for you.",
            time: "10:02",

            sender: "them",
          },
        ],
      },
    ],
    activeChatId: null,  // Store active conversation ID here
  },
  getters: {
    getprofileinfo: (state) => state.profileinfo,
    // get contacts with search
    getContacts(state) {
      return state.contacts;
    },
    getChats: (state) => state.chats,
    // messFeed
    getMessFeed: (state) => state.messFeed,
    // get user
    getUser: (state) => state.user,  // Ensure the user object is returned
    getConversationId: (state) => state.activeChatId,  // New getter for active conversation ID
    getChatType(state) {
      return state.user.chatType;  // Either 'private' or 'group'
    },
    getChatName(state) {
      return state.user.chatType === 'group' ? state.user.chatName : null;
    },
    getChatAvatar(state) {
      return state.user.chatType === 'group' ? state.user.chatAvatar : null;
    },
  },
  mutations: {
    toggleuserSetting(state) {
      state.settingToggle = !state.settingToggle;
    },
    sendMessage(state, payload) {
      state.messFeed.push(payload);
    },
    //openinfo
    openinfo(state) {
      state.openinfo = !state.openinfo;
    },
    setOpenInfo(state, isOpen) {
      state.openinfo = isOpen;
    },
    //open chat
    openChat(state, { data, messages }) {
      console.log("Opening chat with messages:", messages); // Debugging log
      state.activechat = true;
      state.mobileChatSidebar = false;
      state.user = {
        ...data,
        chatType: data.chatType,   // 'private' or 'group'
        chatName: data.chatName,   // Name for group chat
        chatAvatar: data.chatAvatar, // Avatar for the group
        // If private chat, include fullName and status
        ...(data.chatType === 'private' ? {
          fullName: data.fullName,
          status: data.status
        } : {})
      };
      state.messFeed = messages;
      state.activeChatId = data.id; // Store conversationId here
  
      // Debugging: Log the updated user state
      console.log('Updated state.user:', state.user);
    },
    openMobileSidebar(state) {
      state.mobileChatSidebar = !state.mobileChatSidebar;
    },
    setContacts(state, contacts) {
      state.contacts = contacts;
    },
    setUser(state, user) {
      state.user = user;  // Ensure this mutation is correctly updating state
    },
    addMessage(state, newMessage) {
      console.log("Adding new message:", newMessage); // Debugging log
      state.messFeed.push(newMessage);
    },
  },
  actions: {
    addMessage({ commit }, newMessage) {
      commit('addMessage', newMessage); // Commit new message to Vuex
    },
    // commit settingToggle
    toggleuserSetting({ commit }) {
      commit("toggleuserSetting");
    },
    // send message
    sendMessage({ commit }, payload) {
      commit("sendMessage", payload);
    },
    openinfo({ commit }) {
      commit("openinfo");
    },
    async openChat({ commit, dispatch, state }, conversationId) {
      const token = localStorage.getItem('token');
      const activeUser = JSON.parse(localStorage.getItem('activeUser')) || {};
      const activeUserId = activeUser.id;
    
      try {
        // Fetch messages for the conversation
        const response = await axios.get(`https://apidash.smartpress.kz/api/messages/${conversationId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
    
        const messages = response.data.map(message => ({
          ...message,
          sender: message.user_id === activeUserId ? 'me' : 'them',
          avatar: message.user_id === activeUserId ? activeUser.avatar : message.user.avatar,
          time: dayjs(message.created_at).format('HH:mm'),
          userName: message.user ? message.user.name : 'Unknown'
        }));
    
        // Find the selected contact using conversationId
        const selectedContact = state.contacts.find(contact => contact.id === conversationId);
        if (!selectedContact) {
          console.error('Selected contact not found');
          return;
        }
    
        // Prepare the chat data
        const data = {
          id: conversationId,
          chatType: selectedContact.chatType,
          chatName: selectedContact.chatType === 'group' ? selectedContact.chatName : null,
          chatAvatar: selectedContact.chatAvatar,
          ...(selectedContact.chatType === 'private' && {
            fullName: selectedContact.fullName,
            status: selectedContact.status,
          })
        };
    
        commit('openChat', { data, messages });
        commit('setOpenInfo', true);
    
        // Mark messages as read in the backend and refresh contacts list
        await axios.post(`https://apidash.smartpress.kz/api/conversations/${conversationId}/read`, {}, {
          headers: { Authorization: `Bearer ${token}` },
        });
    
        dispatch('fetchContacts');
    
      } catch (error) {
        console.error('Error fetching messages for conversation:', error);
      }
    },
      
        
    async fetchContacts({ commit }) {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get('https://apidash.smartpress.kz/api/conversations', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        const formattedContacts = response.data.map(conversation => {
          return {
            id: conversation.id,
            fullName: conversation.fullName,
            lastmessage: conversation.lastMessage,
            lastmessageTime:  conversation.lastMessageTime ? dayjs(conversation.lastMessageTime).format('HH:mm') : 'No time',
            unredmessage: conversation.unreadMessages,
            avatar: conversation.avatar ? `https://apidash.smartpress.kz/storage/${conversation.avatar}` : 'path_to_default_avatar',
            status: conversation.status === "active" ? "online" : "offline", // Map online/offline status
            created_by: conversation.created_by,
            chatName: conversation.chat_name,
            chatType: conversation.type,  // Either 'private' or 'group'
            chatAvatar: conversation.chat_avatar,
          };
        });
    
        // Commit the formatted contacts to the state
        commit('setContacts', formattedContacts);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    },
    fetchUser({ commit }, userId) {
      const token = localStorage.getItem('token');
    
      axios.get(`https://apidash.smartpress.kz/api/user/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then(response => {
        commit('setUser', response.data);  // Commit user data to the store
              })
      .catch(error => {
        console.error('Error fetching user:', error);
        // Optionally handle error, like showing a notification
      });
    },    
    openMobileSidebar({ commit }) {
      commit("openMobileSidebar");
    },
  },
};
