// store/modules/kanban.js
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  namespaced: true,
  state: {
    
    columModal: false,
    taskModal: false,
    isLoading: null,
    openTaskId: null,
    columns: [
      {
        id: uuidv4(),
        name: 'РИСО/Главный редактор',
        name_ru: 'РИСО/Главный редактор',
        name_kz: 'РИСО/Бас редактор',
        name_en: 'RISO/Chief Editor',
        color: '#4669FA',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Проектный менеджер',
        name_ru: 'Проектный менеджер',
        name_kz: 'Жобаның менеджері',
        name_en: 'Project Manager',
        color: '#4669FA',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Редакционные работы',
        name_ru: 'Редакционные работы',
        name_kz: 'Редакциялық жұмыстар',
        name_en: 'Editorial Work',
        color: '#FA916B',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Типография(сигнальный)',
        name_ru: 'Типография(сигнальный)',
        name_kz: 'Баспа(сигналдық)',
        name_en: 'Printing (Signal)',
        color: '#FA916B',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Типография(тираж)',
        name_ru: 'Типография(тираж)',
        name_kz: 'Баспа(тираж)',
        name_en: 'Printing (Circulation)',
        color: '#00C793',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Отдел продаж',
        name_ru: 'Отдел продаж',
        name_kz: 'Сату бөлімі',
        name_en: 'Sales Department',
        color: '#50C700',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Склад(типография)',
        name_ru: 'Склад(типография)',
        name_kz: 'Қойма(баспа)',
        name_en: 'Warehouse (Printing)',
        color: '#50C003',
        techcards: [],
      },
      {
        id: uuidv4(),
        name: 'Сдан',
        name_ru: 'Сдан',
        name_kz: 'Тапсырылған',
        name_en: 'Delivered',
        color: '#50C003',
        techcards: [],
      },
    ],
    isEditTechCardModalVisible: false,
    isWarehouseTechCardModalVisible: false, // New modal for "Склад(типография)"
    selectedWarehouseTechCard: null, // Store tech card for warehouse modal
    selectedTechCardForEdit: null,
  },
  getters: {
    columns: (state) => state.columns,
    isEditTechCardModalVisible: (state) => state.isEditTechCardModalVisible,
    selectedTechCardForEdit: (state) => state.selectedTechCardForEdit,
    editTechCardId: (state) => state.selectedTechCardForEdit?.id,
    isWarehouseTechCardModalVisible: (state) => state.isWarehouseTechCardModalVisible,
    selectedWarehouseTechCard: (state) => state.selectedWarehouseTechCard,
    whTechCardId: (state) => state.selectedWarehouseTechCard?.id,
  },
  mutations: {
    openEditTechCardModal(state, techCard) {
      state.isEditTechCardModalVisible = true;
      state.selectedTechCardForEdit = techCard;  // Set the tech card data here
      console.log('Vuex mutation: tech card set for edit:', techCard);  // Debugging log
    },
    closeEditTechCardModal(state) {
      state.isEditTechCardModalVisible = false;
      state.selectedTechCardForEdit = null;
    },
    openWarehouseTechCardModal(state, techCard) {
      state.isWarehouseTechCardModalVisible = true;
      state.selectedWarehouseTechCard = techCard; // Store the tech card data for the modal
    },
    closeWarehouseTechCardModal(state) {
      state.isWarehouseTechCardModalVisible = false;
      state.selectedWarehouseTechCard = null; // Clear the modal data
    },
    setColumns(state, techcards) {
      state.columns.forEach((column) => (column.techcards = []));
      techcards.forEach((techcard) => {
        if (techcard.is_editor) {
          state.columns.find((col) => col.name === 'РИСО/Главный редактор').techcards.push(techcard);
        }
        if (techcard.is_project) {
          state.columns.find((col) => col.name === 'Проектный менеджер').techcards.push(techcard);
        }
        if (techcard.is_process) {
          state.columns.find((col) => col.name === 'Редакционные работы').techcards.push(techcard);
        }
        if (techcard.is_signal) {
          state.columns.find((col) => col.name === 'Типография(сигнальный)').techcards.push(techcard);
        }
        if (techcard.is_typography) {
          state.columns.find((col) => col.name === 'Типография(тираж)').techcards.push(techcard);
        }
        if (techcard.is_account) {
          state.columns.find((col) => col.name === 'Отдел продаж').techcards.push(techcard);
        }
        if (techcard.is_stock) {
          state.columns.find((col) => col.name === 'Склад(типография)').techcards.push(techcard);
        }
        if (techcard.is_done) {
          state.columns.find((col) => col.name === 'Сдан').techcards.push(techcard);
        }
      });
    },
    updateTechCardState(state, updatedTechcard) {
      const column = state.columns.find((col) => 
        col.techcards.some((card) => card.id === updatedTechcard.id)
      );
      if (column) {
        const techcard = column.techcards.find((card) => card.id === updatedTechcard.id);
        if (techcard) {
          Object.assign(techcard, updatedTechcard);
        }
      }
    },
  
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
    setError(state, error) {
      toast.error(`Error: ${error}`, { timeout: 2000 });
    },
  },
  actions: {
    async openEditTechCardModal({ commit }, techCardId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://apidash.smartpress.kz/api/tech-cards/${techCardId}`,
          {headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'  // Ensure this header is present
          }
      });
      console.log("Fetched Tech Card Data:", response.data);  // Debugging log
        commit('openEditTechCardModal', response.data);
      } catch (error) {
        console.error('Failed to fetch tech card data:', error);
        commit('openEditTechCardModal', { id: techCardId });  // If no data, open the modal with empty fields
      }
    },
    async openWarehouseTechCardModal({ commit }, techCardId) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://apidash.smartpress.kz/api/tech-cards/${techCardId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        commit('openWarehouseTechCardModal', response.data); // Set the tech card in state
      } catch (error) {
        console.error('Failed to fetch tech card data:', error);
        commit('setError', error);
      }
    },
    closeWarehouseTechCardModal({ commit }) {
      commit('closeWarehouseTechCardModal'); // Close the modal
    },
    closeEditTechCardModal({ commit }) {
      commit('closeEditTechCardModal');
    },
    async fetchTechCards({ commit, dispatch }) {
      commit('setLoading', true);
      try {
        await dispatch('techcards/fetchTechCards', null, { root: true });
        commit('setColumns', this.getters['techcards/techcards']);
      } catch (error) {
        commit('setError', error);
      } finally {
        commit('setLoading', false);
      }
    },
    async updateTechCardKanban({ commit }, { techcardId, updatedProperties }) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(`https://apidash.smartpress.kz/api/tech-cards/kanban/${techcardId}`, updatedProperties, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'  // Ensure this header is present
          }
        });
    
        commit('updateTechCardState', response.data.techcard);
        toast.success('TechCard updated successfully', { timeout: 2000 });
      } catch (error) {
        commit('setError', error);
        toast.error('Failed to update TechCard', { timeout: 2000 });
      }
    },
    async updateSignalEndDate({ commit }, { techcardId, signalEndDate }) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
          `https://apidash.smartpress.kz/api/tech-cards/${techcardId}/signal-end-date`,
          { signalEndDate },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
            },
          }
        );
  
        commit('updateTechCardState', response.data.techcard); // Update the tech card state
        console.log('Signal end date updated successfully');
      } catch (error) {
        console.error('Error updating signal end date:', error);
        throw error; // Rethrow the error for the calling function to handle
      }
    },
  },
};
