<template>
  <router-view />
</template>

<script>
import { useI18n } from 'vue-i18n';
import { onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';

export default {
  setup() {
    const { locale } = useI18n();
    const router = useRouter();

    const updateDocumentTitle = (to) => {
      const routeTitles = to.meta.titles;
      const currentLocale = locale.value || 'en';
      const pageTitle = routeTitles ? routeTitles[currentLocale] : to.name;
      document.title = `Smart University Press - ${pageTitle}`;
    };

    onMounted(() => {
      updateDocumentTitle(router.currentRoute.value);
    });

    router.afterEach((to) => {
      updateDocumentTitle(to);
    });

    // Watch for locale changes to update the title dynamically
    watch(locale, () => {
      updateDocumentTitle(router.currentRoute.value);
    });
  }
};
</script>
