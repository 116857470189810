import axios from 'axios';

const state = {
  applications: [],
  isLoading: false,
  addModal: false,
  editModal: false,
  existingAuthors: [],
  editApplication: null, // Change to null to check if the application is set correctly
  currentApplication: null, // New state to store the current application details
  applicationTeam: [], // New state to store the applicationTeam
  appOwner: '',
};

const getters = {
  applications: (state) => state.applications,
  isLoading: (state) => state.isLoading,
  addModal: (state) => state.addModal,
  editModal: (state) => state.editModal,
  existingAuthors: (state) => state.existingAuthors,
  editApplication: (state) => state.editApplication,
  currentApplication: (state) => state.currentApplication, // New getter to access current application details
  applicationTeam: (state) => state.applicationTeam, // New getter for applicationTeam
  appOwner: (state) => state.appOwner, // New getter for applicationTeam

};

const mutations = {
  SET_APPLICATIONS(state, applications) {
    state.applications = applications;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ADD_MODAL(state, status) {
    state.addModal = status;
  },
  SET_EDIT_MODAL(state, status) {
    state.editModal = status;
  },
  SET_EXISTING_AUTHORS(state, authors) {
    state.existingAuthors = authors;
  },
  SET_EDIT_APPLICATION(state, application) {
    state.editApplication = application;
  },
  SET_CURRENT_APPLICATION(state, application) {
    state.currentApplication = application; // New mutation to set current application details
  },
  SET_APPLICATION_TEAM(state, team) { // New mutation to set applicationTeam
    state.applicationTeam = team;
  },
  SET_APPOWNER(state, owner) { // New mutation to set applicationTeam
    state.appOwner = owner;
  },
};

const actions = {
  async fetchApplications({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/applications', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_APPLICATIONS', response.data.applications);
    } catch (error) {
      console.error('Error fetching applications:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchExistingAuthors({ commit }) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/applications/create', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_EXISTING_AUTHORS', response.data.authors);
    } catch (error) {
      console.error('Error fetching existing authors:', error);
    }
  },

  async fetchApplicationForEdit({ commit }, id) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/applications/${id}/edit`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_EDIT_APPLICATION', response.data.application);
      commit('SET_EDIT_MODAL', true);
    } catch (error) {
      console.error('Error fetching application for edit:', error);
    }
  },
  async fetchApplicationDetails({ commit }, id) { // New action to fetch application details
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/application/details/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_CURRENT_APPLICATION', response.data.application); // Set the fetched application details
      commit('SET_APPLICATION_TEAM', response.data.applicationTeam); // Set the fetched applicationTeam
      commit('SET_APPOWNER', response.data.appOwner); // Set the fetched applicationTeam

    } catch (error) {
      console.error('Error fetching application details:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  openAddModal({ commit }) {
    commit('SET_ADD_MODAL', true);
  },

  closeAddModal({ commit }) {
    commit('SET_ADD_MODAL', false);
  },

  openEditModal({ commit }) {
    commit('SET_EDIT_MODAL', true);
  },

  closeEditModal({ commit }) {
    commit('SET_EDIT_MODAL', false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
