import axios from 'axios';

const state = {
  applications: [],
  isLoading: false,
  addModal: false,
  editModal: false,
  changeStatusModal: false,
  existingAuthors: [],
  editApplication: {
    id: null,
    book_name: '',
    annotation_kz: '',
    annotation_rus: '',
    annotation_en: '',
    agreed: false,
    authors: [],
    files: [],
  },
};

const getters = {
  applications: (state) => state.applications,
  isLoading: (state) => state.isLoading,
  addModal: (state) => state.addModal,
  editModal: (state) => state.editModal,
  changeStatusModal: (state) => state.changeStatusModal,
  existingAuthors: (state) => state.existingAuthors,
  editApplication: (state) => state.editApplication,
};

const mutations = {
  SET_APPLICATIONS(state, applications) {
    state.applications = applications;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_ADD_MODAL(state, status) {
    state.addModal = status;
  },
  SET_EDIT_MODAL(state, status) {
    state.editModal = status;
  },
  SET_CHANGE_STATUS_MODAL(state, status) {
    state.changeStatusModal = status;
  },
  SET_EXISTING_AUTHORS(state, authors) {
    state.existingAuthors = authors;
  },
  SET_EDIT_APPLICATION(state, application) {
    state.editApplication = application;
  },
  CLOSE_ADD_MODAL(state) {
    state.addModal = false;
  },
  CLOSE_EDIT_MODAL(state) {
    state.editModal = false;
  },
  CLOSE_CHANGE_STATUS_MODAL(state) {
    state.changeStatusModal = false;
  },
};

const actions = {
  async fetchApplications({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/applications', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_APPLICATIONS', response.data.applications);
    } catch (error) {
      console.error('Error fetching applications:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchExistingAuthors({ commit }) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/applications/create', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_EXISTING_AUTHORS', response.data.authors);
      commit('SET_ADD_MODAL', true);
    } catch (error) {
      console.error('Error fetching existing authors:', error);
    }
  },
  async fetchApplicationForEdit({ commit }, id) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/applications/${id}/edit`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_EDIT_APPLICATION', response.data.application);
      commit('SET_EDIT_MODAL', true);
    } catch (error) {
      console.error('Error fetching application for edit:', error);
    }
  },
  async updateApplication({ commit, state }) {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      const editApplication = state.editApplication;
      formData.append('book_name', editApplication.book_name);
      formData.append('annotation_kz', editApplication.annotation_kz);
      formData.append('annotation_rus', editApplication.annotation_rus);
      formData.append('annotation_en', editApplication.annotation_en);

      editApplication.files.forEach((fileObj) => {
        if (fileObj.file) {
          formData.append(fileObj.name, fileObj.file);
        }
      });

      editApplication.authors.forEach((author, index) => {
        if (author.isNew) {
          formData.append(`authors[${index}][name]`, author.name);
          formData.append(`authors[${index}][email]`, author.email);
          formData.append(`authors[${index}][phone]`, author.phone);
        } else {
          formData.append(`exist_authors[]`, author.existingAuthorId);
        }
      });

      await axios.post(`https://apidash.smartpress.kz/api/applications/${state.editApplication.id}/edit`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_EDIT_MODAL', false);
      commit('SET_LOADING', true);
      const response = await axios.get('https://apidash.smartpress.kz/api/applications', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_APPLICATIONS', response.data.applications);
    } catch (error) {
      console.error('Error updating application:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateApplicationStatus({ commit, state }, status) {
    try {
      const token = localStorage.getItem('token');
      const formData = new FormData();
      formData.append('status', status);

      await axios.post(`https://apidash.smartpress.kz/api/applications/${state.editApplication.id}/status`, formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_CHANGE_STATUS_MODAL', false);
      commit('SET_LOADING', true);
      const response = await axios.get('https://apidash.smartpress.kz/api/applications', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('SET_APPLICATIONS', response.data.applications);
    } catch (error) {
      console.error('Error updating application status:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  openAddModal({ commit }, application) {
    commit('SET_EDIT_APPLICATION', application);
    commit('SET_ADD_MODAL', true);
  },
  openChangeStatusModal({ commit }, application) {
    commit('SET_EDIT_APPLICATION', application);
    commit('SET_CHANGE_STATUS_MODAL', true);
  },
  closeAddModal({ commit }) {
    commit('CLOSE_ADD_MODAL');
  },
  closeEditModal({ commit }) {
    commit('CLOSE_EDIT_MODAL');
  },
  closeChangeStatusModal({ commit }) {
    commit('CLOSE_CHANGE_STATUS_MODAL');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
