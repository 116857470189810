// store/modules/todo.js
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const state = {
  addModal: false,
  editModal: false,
  mobileTodoSidebar: false,
  todos: [],
  workTypes: [],
  projects: [],
  selectedProject: null,
  selectedProjectName: '',
  isLoading: false,
};

const getters = {
  addModal: (state) => state.addModal,
  editModal: (state) => state.editModal,
  mobileTodoSidebar: (state) => state.mobileTodoSidebar,
  todos: (state) => state.todos,
  workTypes: (state) => state.workTypes,
  projects: (state) => state.projects,
  selectedProject: (state) => state.selectedProject,
  selectedProjectName: (state) => state.selectedProjectName,
  isLoading: (state) => state.isLoading,
};

const mutations = {
  SET_ADD_MODAL(state, value) {
    state.addModal = value;
  },
  SET_EDIT_MODAL(state, value) {
    state.editModal = value;
  },
  SET_MOBILE_TODO_SIDEBAR(state, value) {
    state.mobileTodoSidebar = value;
  },
  SET_TODOS(state, todos) {
    state.todos = todos;
  },
  SET_WORK_TYPES(state, workTypes) {
    state.workTypes = workTypes;
  },
  SET_PROJECTS(state, projects) {
    state.projects = projects;
  },
  SET_SELECTED_PROJECT(state, projectId) {
    state.selectedProject = projectId;
    const project = state.projects.find((p) => p.id === projectId);
    state.selectedProjectName = project ? project.name : '';
  },
  SET_LOADING(state, value) {
    state.isLoading = value;
  },
};

const actions = {
  async fetchProjects({ commit }) {
    try {
      const response = await axios.get('/api/projects');
      commit('SET_PROJECTS', response.data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  },
  async fetchWorkTypes({ commit }, projectId) {
    commit('SET_LOADING', true);
    try {
      const response = await axios.get(`/api/projects/${projectId}/work-types`);
      commit('SET_WORK_TYPES', response.data);
    } catch (error) {
      console.error('Error fetching work types:', error);
      commit('SET_WORK_TYPES', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async addTodo({ commit, dispatch }, todo) {
    try {
      await axios.post('/api/todos', todo);
      dispatch('fetchTodos');
    } catch (error) {
      console.error('Error adding todo:', error);
    }
  },
  openTodoModal({ commit }) {
    commit('SET_ADD_MODAL', true);
  },
  closeTodoModal({ commit }) {
    commit('SET_ADD_MODAL', false);
  },
  openEditTodoModal({ commit }) {
    commit('SET_EDIT_MODAL', true);
  },
  closeEditTodoModal({ commit }) {
    commit('SET_EDIT_MODAL', false);
  },
  openMobileTodoSidebar({ commit }) {
    commit('SET_MOBILE_TODO_SIDEBAR', true);
  },
  closeMobileTodoSidebar({ commit }) {
    commit('SET_MOBILE_TODO_SIDEBAR', false);
  },
  async selectProject({ commit, dispatch }, projectId) {
    commit('SET_SELECTED_PROJECT', projectId);
    await dispatch('fetchWorkTypes', projectId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
