<template>
  <div :class="$store.state.semidark ? 'dark' : ''">
    <div
      :class="`sidebar-wrapper bg-white dark:bg-slate-800 ${
        $store.state.skin === 'bordered'
          ? 'border-r border-gray-5002 dark:border-slate-700'
          : 'shadow-base'
      } ${
        $store.state.sidebarCollasp ? closeClass : openClass
      }`"
    >
      <div
        :class="`logo-segment flex justify-between items-center bg-white dark:bg-slate-800 z-[9] py-6 sticky top-0 px-4 ${
          $store.state.sidebarCollasp ? closeClass : openClass
        } ${
          $store.state.skin === 'bordered'
            ? ' border-b border-r border-gray-5002 dark:border-slate-700'
            : ' border-none'
        }`"
      >
          <router-link
            :to="userRole === 9 ? { name: 'authorhome' } : userRole === 3 ? { name: 'inithome' } : userRole === 4 ? { name: 'project-managerhome' } : { name: 'home' }"
            v-if="!$store.state.sidebarCollasp"
          >
            <img
              src="@/assets/images/logo/logo.svg"
              alt=""
              v-if="!$store.state.isDark && !$store.state.semidark"
            />
            <img
              src="@/assets/images/logo/logo-white.svg"
              alt=""
              v-if="$store.state.isDark || $store.state.semidark"
            />
          </router-link>
          <router-link
            :to="userRole === 9 ? { name: 'authorhome' } : userRole === 3 ? { name: 'inithome' } : { name: 'home' }"
            v-if="$store.state.sidebarCollasp"
          >
            <img
              src="@/assets/images/logo/logo-c.svg"
              alt=""
              v-if="!$store.state.isDark && !$store.state.semidark"
            />
            <img
              src="@/assets/images/logo/logo-c-white.svg"
              alt=""
              v-if="$store.state.isDark || $store.state.semidark"
            />
          </router-link>
        <span
          class="cursor-pointer text-slate-900 dark:text-white text-2xl"
          v-if="!$store.state.sidebarCollasp"
          @click="toggleSidebar"
        ><Icon icon="heroicons-outline:menu-alt-3" /></span>
      </div>
      <div
        class="h-[60px] absolute top-[80px] nav-shadow z-[1] w-full transition-all duration-200 pointer-events-none"
        :class="[shadowbase ? ' opacity-100' : ' opacity-0']"
      ></div>

      <SimpleBar
        class="sidebar-menu px-4 h-[calc(100%-80px)]"
        @created="onSimpleBarCreated"
      >
        <Navmenu :items="menuItems" />
        <!-- <Transition @enter="enterWidget" @leave="leaveWidget">
          <div
            class="bg-slate-900 mb-10 mt-24 p-4 relative text-center rounded-2xl text-white"
            v-if="!$store.state.sidebarCollasp"
          >
            <img
              src="@/assets/images/svg/rabit.svg"
              alt=""
              class="mx-auto relative -mt-[73px]"
            />
            <div class="max-w-[160px] mx-auto mt-6">
              <div class="widget-title">Unlimited Access</div>
              <div class="text-xs font-light">
                Upgrade your system to business plan
              </div>
            </div>
            <div class="mt-6">
              <button
                class="btn bg-white hover:bg-opacity-80 text-slate-900 btn-sm w-full block"
              >
                Upgrade
              </button>
            </div>
          </div>
        </Transition> -->
      </SimpleBar>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue";
import { defineComponent, ref, onMounted, computed } from "vue";
import Navmenu from "./Navmenu";
import { gsap } from "gsap";
import { SimpleBar } from "simplebar-vue3";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    Icon,
    Navmenu,
    SimpleBar,
  },
  setup() {
    const store = useStore();
    const shadowbase = ref(false);
    const simplebarInstance = ref(null);
    const openClass = "w-[248px]";
    const closeClass = "w-[72px] close_sidebar";

    const menuItems = computed(() => store.getters.menuItems);

    const activeUser = JSON.parse(localStorage.getItem('activeUser')) || {};
    const userRole = ref(activeUser.role_id || null);

    const fetchMenuItems = async () => {
      await store.dispatch('fetchMenuItems');
    };

    const toggleSidebar = () => {
      store.commit('setSidebarCollasp');
    };

    const onSimpleBarCreated = (instance) => {
      simplebarInstance.value = instance;
      simplebarInstance.value
        .getScrollElement()
        .addEventListener("scroll", () => {
          if (simplebarInstance.value.getScrollElement().scrollTop > 50) {
            simplebarInstance.value.getScrollElement().classList.add("scroll");
            shadowbase.value = true;
          } else {
            simplebarInstance.value
              .getScrollElement()
              .classList.remove("scroll");
            shadowbase.value = false;
          }
        });
    };

    const enterWidget = (el) => {
      gsap.fromTo(
        el,
        { x: 0, opacity: 0, scale: 0.5 },
        { x: 0, opacity: 1, duration: 0.3, scale: 1 }
      );
    };

    const leaveWidget = (el) => {
      gsap.fromTo(
        el,
        { x: 0, opacity: 1, scale: 1 },
        { x: 0, opacity: 0, duration: 0.3, scale: 0.5 }
      );
    };

    onMounted(async () => {
      if (!menuItems.value.length) {
        await fetchMenuItems();
      }
    });

    return {
      shadowbase,
      simplebarInstance,
      openClass,
      closeClass,
      menuItems,
      toggleSidebar,
      onSimpleBarCreated,
      enterWidget,
      leaveWidget,
      userRole,
    };
  }
});
</script>
<style lang="scss">
.sidebar-wrapper {
  @apply fixed left-0 top-0 h-screen z-[999];
}
.close_sidebar.sidebar-wrapper {
  @apply absolute bottom-0 h-full;
}

.nav-shadow {
  background: linear-gradient(
    rgb(255, 255, 255) 5%,
    rgba(255, 255, 255, 75%) 45%,
    rgba(255, 255, 255, 20%) 80%,
    transparent
  );
}
.dark {
  .nav-shadow {
    background: linear-gradient(
      rgba(#1e293b, 100%) 5%,
      rgba(#1e293b, 75%) 45%,
      rgba(#1e293b, 20%) 80%,
      transparent
    );
  }
}
</style>
