<template>
    <div class="dropdown">
      <!-- Trigger button showing the selected language -->
      <div class="dropdown-trigger" @click="toggleDropdown">
        <img :src="selectedOption.image" alt="" class="flag-icon" />
        <span>{{ selectedOption.label }}</span>
      </div>
      
      <!-- Dropdown options excluding the selected language -->
      <div v-if="isOpen" class="dropdown-options">
        <div
          v-for="(option, index) in filteredOptions"
          :key="index"
          class="dropdown-option"
          @click="selectOption(option)"
        >
          <img :src="option.image" alt="" class="flag-icon" />
          <span>{{ option.label }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      options: {
        type: Array,
        required: true
      },
      modelValue: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        isOpen: false
      };
    },
    computed: {
      selectedOption() {
        return this.options.find(option => option.value === this.modelValue) || this.options[0];
      },
      filteredOptions() {
        // Exclude the currently selected option from the dropdown list
        return this.options.filter(option => option.value !== this.modelValue);
      }
    },
    methods: {
      toggleDropdown() {
        this.isOpen = !this.isOpen;
      },
      selectOption(option) {
        this.$emit("update:modelValue", option.value);
        this.isOpen = false;
      }
    }
  };
  </script>
  
  <style scoped>
  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
  .dropdown-trigger {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    background-color: white;
  }
  
  .dropdown-trigger, .dropdown-options {
    border: none; /* Remove the border */
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1); /* Optional: Add subtle shadow */
  }
  
  .dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border-radius: 4px;
    margin-top: 4px;
    z-index: 10;
  }
  
  .dropdown-option {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-option:hover {
    background-color: #f0f0f0;
  }
  
  .flag-icon {
    width: 24px;
    height: 24px;
    border-radius: 50%; /* Make the flag circular */
    margin-right: 8px;
    object-fit: cover; /* Ensure the flag image fills the circular area */
  }
  </style>
  