// store/modules/projects.js
import axios from 'axios';

const state = {
  projectForm: {
    id: '',
    name: '',
    bookName:'',
    created_date: '',
    deadline: '',
    description: '',
    projectsNew: [],
    projectsInProgress: [],
    projectsDone: [],
    isLoading: false,
    error: null,
    manager: '',
    fname_gdisk: '',
    fname_print: '',
    fname_signal: '',
    editor_requirements: '',  // New field
    designer_requirements: '',  // New field
    templator_requirements: '',  // New field
  },
  currentProject: null, // Add this to store the current project data
  isAddProjectModalVisible: false,
  formErrors: {},
  isEditProjectModalVisible: false,
  workTypes: [], // Add this to store work types
  responses: [],  // Store users who responded to the project
  projects: [],  // Initialize projects as an empty array
};

const mutations = {
  SET_PROJECT_FORM(state, data) {
    state.projectForm = data;
  },
  TOGGLE_ADD_PROJECT_MODAL(state, isVisible) {
    state.isAddProjectModalVisible = isVisible;
  },
  SET_RESPONSES(state, responses) {
    state.responses = responses;
  },
  RESET_PROJECT_FORM(state) {
    state.projectForm = {
      name: '',
      created_date: '',
      deadline: '',
      description: '',
      editor_requirements: '',  // Reset field
      designer_requirements: '',  // Reset field
      templator_requirements: '',  // Reset field
      projectsNew: [],
      projectsInProgress: [],
      projectsDone: [],
    };
  },
  SET_CURRENT_PROJECT(state, project) {
    state.currentProject = project; // Store the fetched project data
  },
  SET_FORM_ERRORS(state, errors) {
    state.formErrors = errors;
  },
  CLEAR_FORM_ERRORS(state) {
    state.formErrors = {};
  },
  SET_PROJECTS_NEW(state, projects) {
    state.projectForm.projectsNew = projects;
  },
  SET_PROJECTS_IN_PROGRESS(state, projects) {
    state.projectForm.projectsInProgress = projects;
  },
  SET_PROJECTS_DONE(state, projects) {
    state.projectForm.projectsDone = projects;
  },
  SET_LOADING(state, isLoading) {
    state.projectForm.isLoading = isLoading;
  },
  SET_ERROR(state, error) {
    state.projectForm.error = error;
  },
  SET_MANAGER(state, manager) {
    state.projectForm.manager = manager;
  },
  TOGGLE_EDIT_PROJECT_MODAL(state, isVisible) {
    state.isEditProjectModalVisible = isVisible;
  },
  SET_WORK_TYPES(state, workTypes) {
    state.workTypes = workTypes;
  },
  updateProjectStatus(state, { id, status }) {
    const project = state.projects.find(p => p.id === id);
    if (project) {
      project.status = status;
    }
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
};

const actions = {
  async fetchProjectInitialData({ commit }, techcardId) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/project/create/${techcardId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const techCardData = response.data.techCard;
      const workTypes = techCardData.work_types;
      commit('SET_PROJECT_FORM', {
        ...response.data.project,
        id: response.data.project.id,  // Ensure the project ID is set
        techcard_id: techcardId,
        name: techCardData.book_name,
      });
      commit('SET_WORK_TYPES', workTypes);      
      commit('TOGGLE_ADD_PROJECT_MODAL', true);
    } catch (error) {
      console.error('Error fetching project initial data:', error);
    }
  },
  async fetchProjectById({ commit }, projectId) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/project/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_CURRENT_PROJECT', response.data.project);
    } catch (error) {
      console.error('Error fetching project:', error);
      throw error;
    }
  },
  async fetchResponses({ commit }, projectId) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/project/allresponce`, {
        params: { project_id: projectId },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_RESPONSES', response.data.responses);
    } catch (error) {
      console.error('Error fetching project responses:', error);
      commit('SET_ERROR', 'Failed to fetch project responses');
    }
  },
  closeAddProjectModal({ commit }) {
    commit('TOGGLE_ADD_PROJECT_MODAL', false);
    commit('RESET_PROJECT_FORM');
    commit('CLEAR_FORM_ERRORS');
  },
  async createProject({ state, commit }) {
    try {
      const token = localStorage.getItem('token');
      const { techcard_id, ...projectData } = state.projectForm;
      const response = await axios.post(`https://apidash.smartpress.kz/api/project/create/${techcard_id}`, projectData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Project created:', response.data);
      commit('RESET_PROJECT_FORM');
      commit('TOGGLE_ADD_PROJECT_MODAL', false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_FORM_ERRORS', error.response.data.errors);
      } else {
        console.error('Error creating project:', error);
      }
    }
  },
  async fetchProjects({ commit }) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/project', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('SET_PROJECTS_NEW', response.data.projectsNew);
      commit('SET_PROJECTS_IN_PROGRESS', response.data.projectsInProgres); // Typo here
      commit('SET_PROJECTS_DONE', response.data.projectsDone);
      commit('SET_MANAGER', response.data.manager);
    } catch (error) {
      commit('SET_ERROR', 'Failed to fetch projects');
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchProjectForEdit({ commit }, projectId) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/project/edit/${projectId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const techCardData = response.data.techCard;
      const projectData = response.data.project;
      const workTypes = techCardData.work_types;

      commit('SET_PROJECT_FORM', {
        ...projectData,
        id: projectData.id,  // Ensure the project ID is set
        fname_gdisk: techCardData.fname_gdisk,
        fname_print: techCardData.fname_print,
        fname_signal: techCardData.fname_signal
       });
       commit('SET_WORK_TYPES', workTypes);      
      commit('TOGGLE_EDIT_PROJECT_MODAL', true);
    } catch (error) {
      console.error('Error fetching project data for edit:', error);
    }
  },
  async updateProject({ state, commit }, projectId) {
    try {
      const token = localStorage.getItem('token');
      await axios.post(`https://apidash.smartpress.kz/api/project/edit/${projectId}`, state.projectForm, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      commit('TOGGLE_EDIT_PROJECT_MODAL', false);
      await this.dispatch('projects/fetchProjects'); // Refresh the projects grid after update
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        commit('SET_FORM_ERRORS', error.response.data.errors);
      } else {
        console.error('Error updating project:', error);
      }
    }
  },
  closeEditModal({ commit }) {
    commit('TOGGLE_EDIT_PROJECT_MODAL', false);
    commit('RESET_PROJECT_FORM');
    commit('CLEAR_FORM_ERRORS');
  },
  async fetchWorkTypes({ commit }, projectId) {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/project/${projectId}/work_types`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log("API Response:", response); // Log the full response
      return response.data; // Ensure this line returns the work types data
    } catch (error) {
      console.error("Error fetching work types:", error);
      throw error; // Propagate the error so it can be caught by the calling method
    }
  },
};

const getters = {
  projectForm: (state) => state.projectForm,
  isAddProjectModalVisible: (state) => state.isAddProjectModalVisible,
  formErrors: (state) => state.formErrors,
  projectsNew: (state) => state.projectForm.projectsNew,
  projectsInProgress: (state) => state.projectForm.projectsInProgress,
  projectsDone: (state) => state.projectForm.projectsDone,
  isLoading: (state) => state.projectForm.isLoading,
  error: (state) => state.projectForm.error,
  manager: (state) => state.projectForm.manager,
  isEditProjectModalVisible: (state) => state.isEditProjectModalVisible,
  workTypes: (state) => state.workTypes,
  currentProject: (state) => state.currentProject, // Getter to access current project
  responses: (state) => state.responses, // Getter for responses
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
