<template>
  <div>
    <!-- Language Selection Using CustomSelect Component -->
    <CustomSelect
      :options="languages"
      :modelValue="selectLanguage.value"
      @update:modelValue="changeLanguage"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import CustomSelect from "@/components/Select/SelectLanguage.vue";

const languages = [
  { label: "En", value: "en", image: require("@/assets/images/flags/usa.png") },
  { label: "Ru", value: "ru", image: require("@/assets/images/flags/ru.png") },
  { label: "Kz", value: "kz", image: require("@/assets/images/flags/kz.png") }
];

const selectLanguage = ref(
  languages.find((lang) => lang.value === localStorage.getItem("locale")) || languages[0]
);

const { locale } = useI18n();

function changeLanguage(langCode) {
  const selectedLang = languages.find((lang) => lang.value === langCode);
  if (selectedLang) {
    locale.value = selectedLang.value;
    selectLanguage.value = selectedLang;
    localStorage.setItem("locale", selectedLang.value);
  }
}
</script>
