// src/store/modules/profile.js
import axios from 'axios';

const state = {
  user: null,
  isLoading: false,
};

const getters = {
  getUser: (state) => state.user,
  isLoading: (state) => state.isLoading,
};

const actions = {
  async fetchUserProfile({ commit }) {
    commit('setLoading', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://apidash.smartpress.kz/api/userprofile', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      commit('setUser', response.data);
    } catch (error) {
      console.error('Error fetching user profile:', error);
    } finally {
      commit('setLoading', false);
    }
  },


 
};

const mutations = {
  setUser(state, userData) {
    state.user = userData.user;
    state.user.profile = userData.profile; // Directly set the profile data
    state.user.role_name = userData.role_name; // Set the role name
  },
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
