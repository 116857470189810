import axios from 'axios';

const state = {
  user: null,
  profile: null,
  isLoading: false,
};

const getters = {
  user(state) {
    return state.user;
  },
  profile(state) {
    return state.profile;
  },
  isLoading: (state) => state.isLoading,
};

const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_USER_AVATAR(state, avatarPath) {
    if (state.user) {
      state.user.avatar = avatarPath;
    }
  },
};

const actions = {
  async fetchProfile({ commit }, userId) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://apidash.smartpress.kz/api/user/${userId}`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('API Response:', response.data); // Log API response
      const { user, profile } = response.data;
      
      // Commit both user and profile to the store
      commit('SET_USER', user);
      commit('SET_PROFILE', profile);
    } catch (error) {
      console.error('Error fetching profile:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateProfile({ commit }, updatedProfile) {
    commit('SET_LOADING', true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('https://apidash.smartpress.kz/api/authorprofile/edit', updatedProfile, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('Profile updated:', response.data.user); // Log update response
      commit('SET_PROFILE', response.data.user);
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
