<template>
  <ul>
    <li
      v-for="(item, i) in items"
      :key="i"
      :class="`
      ${item.child ? 'item-has-children' : ''}
      ${activeSubmenu === i ? 'open' : ''}
      ${this.$route.name === item.link ? 'menu-item-active' : ''}
      `"
      class="single-sidebar-menu"
    >
      <!-- Single menu with no children -->
      <router-link
        :to="`${item.link}`"
        class="menu-link"
        v-if="!item.child && !item.isHeadr"
      >
        <span class="menu-icon" v-if="item.icon">
          <Icon :icon="item.icon" />
        </span>
        <div class="text-box">{{ getMenuTitle(item) }}</div>
      </router-link>

      <!-- Only for menu label -->
      <div v-else-if="item.isHeadr && !item.child" class="menulabel">
        {{ getMenuTitle(item) }}
      </div>

      <!-- Submenu parent -->
      <div
        class="menu-link"
        v-else
        :class="
          activeSubmenu === i ? 'parent_active not-collapsed' : 'collapsed'
        "
        @click="toggleSubmenu(i)"
      >
        <div class="flex-1 flex items-start">
          <span class="menu-icon" v-if="item.icon">
            <Icon :icon="item.icon" />
          </span>
          <div class="text-box">{{ getMenuTitle(item) }}</div>
        </div>
        <div class="flex-0">
          <div
            class="menu-arrow transform transition-all duration-300"
            :class="activeSubmenu === i ? 'rotate-90' : ''"
          >
            <Icon icon="heroicons-outline:chevron-right" />
          </div>
        </div>
      </div>

      <!-- Submenu -->
      <Transition
        enter-active-class="submenu_enter-active"
        leave-active-class="submenu_leave-active"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
      >
        <ul class="sub-menu" v-if="i === activeSubmenu">
          <li
            v-for="(ci, index) in item.child"
            :key="index"
            class="block pl-4 pr-1 mb-4 first:mt-4"
          >
            <router-link :to="ci.childlink" v-slot="{ isActive }">
              <span
                class="text-sm flex space-x-3 items-center transition-all duration-150"
                :class="isActive
                  ? 'text-slate-900 dark:text-white font-medium'
                  : 'text-slate-600 dark:text-slate-300'"
              >
                <span
                  class="h-2 w-2 rounded-full border border-slate-600 dark:border-slate-300 inline-block flex-none"
                  :class="isActive
                    ? 'bg-slate-900 dark:bg-slate-300 ring-4 ring-opacity-[15%] ring-black-500 dark:ring-slate-300 dark:ring-opacity-20'
                    : ''"
                ></span>
                <span class="flex-1">
                  {{ getMenuTitle(ci) }}
                </span>
              </span>
            </router-link>
          </li>
        </ul>
      </Transition>
    </li>
  </ul>
</template>

<script>
import { useI18n } from "vue-i18n";
import Icon from "../Icon";

export default {
  components: {
    Icon,
  },
  props: {
    items: { type: Array, required: true },
  },
  data() {
    return {
      activeSubmenu: null,
    };
  },
  methods: {
    getMenuTitle(item) {
      const { locale } = useI18n();
      if (locale.value === "en") return item.title_en;
      if (locale.value === "kz") return item.title_kz;
      return item.title_ru;
    },
    beforeEnter(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = "0px";
        }
        element.style.display = null;
      });
    },
    enter(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`;
        });
      });
    },
    afterEnter(element) {
      element.style.height = null;
    },
    beforeLeave(element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`;
        }
      });
    },
    leave(element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = "0px";
        });
      });
    },
    afterLeave(element) {
      element.style.height = null;
    },
    toggleSubmenu(index) {
      this.activeSubmenu = this.activeSubmenu === index ? null : index;
    },
  },
};
</script>

<style lang="scss">
/* Add your styles here */
</style>

<style lang="scss">
.submenu_enter-active,
.submenu_leave-active {
  overflow: hidden;
  transition: all 0.34s linear;
}

.not-collapsed .has-icon {
  transition: all 0.34s linear;
}
.not-collapsed .has-icon {
  @apply transform rotate-180;
}

// single sidebar menu css
.single-sidebar-menu {
  @apply relative;
  .menulabel {
    @apply text-slate-800 dark:text-slate-300 text-xs font-semibold uppercase mb-4 mt-4;
  }
  > .menu-link {
    @apply flex text-slate-600 font-medium dark:text-slate-300 text-sm capitalize px-[10px] py-3 rounded-[4px] cursor-pointer;
  }
  .menu-icon {
    @apply icon-box inline-flex items-center text-slate-600 dark:text-slate-300 text-lg mr-3;
  }
}
// menu item has chilren
.item-has-children {
  .menu-arrow {
    @apply h-5 w-5 text-base text-slate-300 bg-slate-100 dark:bg-[#334155] dark:text-slate-300 rounded-full flex justify-center items-center;
  }
}

// close sidebar css
.close_sidebar .menulabel {
  @apply hidden;
}
.close_sidebar .simplebar-content-wrapper,
.close_sidebar .simplebar-mask {
  overflow: visible !important;
}
.close_sidebar .simplebar-scrollbar {
  display: none !important;
}
.close_sidebar .simplebar-offset {
  bottom: 0 !important;
  right: 0 !important;
}
.close_sidebar {
  .menu-arrow {
    @apply hidden;
  }
  .single-sidebar-menu {
    .text-box {
      @apply absolute  left-full ml-5 w-[180px] top-0 px-4 py-3 bg-white shadow-dropdown rounded-[4px] dark:bg-slate-800 z-[999] invisible opacity-0 transition-all duration-150;
    }
    &:hover {
      .text-box {
        @apply visible opacity-100;
      }
    }
  }
  .item-has-children {
    .text-box {
      @apply hidden;
    }

    > ul {
      @apply ml-4 absolute left-full top-0 w-[230px] bg-white shadow-dropdown rounded-[4px] dark:bg-slate-800 z-[999] px-4 pt-3 transition-all duration-150 invisible opacity-0;
      display: block !important;
    }
    &:hover {
      > ul {
        @apply visible opacity-100;
      }
    }
  }
}

// active menu
.item-has-children {
  .parent_active {
    @apply bg-secondary-500 bg-opacity-20;
    .icon-box,
    .menu-icon,
    .text-box {
      @apply text-slate-700 dark:text-slate-200;
    }
    .menu-arrow {
      @apply bg-secondary-500 text-slate-600 text-opacity-70 bg-opacity-30 dark:text-white;
    }
  }
}
.menu-item-active {
  .menu-link {
    @apply bg-slate-800 dark:bg-slate-700;
    .icon-box,
    .menu-icon,
    .text-box {
      @apply text-white dark:text-slate-300;
    }
  }
}
</style>
