<template>
  <Dropdown classMenuItems="md:w-[300px] top-[58px]" classItem="px-4 py-2">
    <span
      class="relative lg:h-[32px] lg:w-[32px] lg:bg-gray-500-f7 mr-6 last:mr-0 text-slate-900 lg:dark:bg-slate-900 dark:text-white cursor-pointer rounded-full text-[20px] flex flex-col items-center justify-center"
    >
      <Icon icon="heroicons-outline:bell" />
      <!-- Display count of unread notifications -->
      <span
        v-if="unreadCount > 0"
        class="absolute lg:right-0 lg:top-0 -top-2 -right-2 h-4 w-4 bg-red-500 text-[8px] font-semibold flex flex-col items-center justify-center rounded-full text-white z-[99]"
      >
        {{ unreadCount }}
      </span>
    </span>

    <template v-slot:menus>
      <div class="flex justify-between px-4 py-4 border-b border-slate-100 dark:border-slate-600">
        <div class="text-sm text-slate-800 dark:text-slate-200 font-medium leading-6">
          {{ $t('notifications.title') }}
        </div>
        <div class="text-slate-800 dark:text-slate-200 text-xs md:text-right">
          <router-link :to="{ name: 'notifications' }" class="underline">
            {{ $t('notifications.viewAll') }}
          </router-link>
        </div>
      </div>

      <div class="divide-y divide-slate-100 dark:divide-slate-800">
        <MenuItem
          v-slot="{ active }"
          v-for="(notification, index) in unreadNotifications"
          :key="notification.id"
          @click="handleNotificationClick(notification)"
        >
          <div
            :class="`${
              active
                ? 'bg-slate-100 dark:bg-slate-700 dark:bg-opacity-70 text-slate-800'
                : 'text-slate-600 dark:text-slate-300'
            } block w-full px-4 py-2 text-sm cursor-pointer`"
          >
            <div class="flex text-left">
              <div class="flex-1">
                <div :class="`text-sm ${active ? 'text-slate-600 dark:text-slate-300' : 'text-slate-600 dark:text-slate-300'}`">
                  {{ getMessageByLocale(notification.data) }}
                </div>
                <div class="text-slate-400 dark:text-slate-400 text-xs mt-1">
                  {{ formatTimeAgo(notification.created_at) }}
                </div>
              </div>
              <div class="flex-0">
                <span class="h-[10px] w-[10px] bg-danger-500 border border-white rounded-full inline-block"></span>
              </div>
            </div>
          </div>
        </MenuItem>
      </div>
    </template>
  </Dropdown>
</template>
<script>
import Dropdown from "@/components/Dropdown";
import Icon from "@/components/Icon";
import { MenuItem } from "@headlessui/vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";

export default {
  components: {
    Icon,
    Dropdown,
    MenuItem,
  },
  data() {
    return {
      notifications: [], // Store all notifications here
      unreadCount: 0, // Track unread notifications count
    };
  },
  computed: {
    unreadNotifications() {
      // Filter to show only unread notifications
      return this.notifications.filter((n) => n.read_at === null);
    },
  },
  mounted() {
    this.fetchNotifications();
  },
  methods: {
    async fetchNotifications() {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("https://apidash.smartpress.kz/api/notifications", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Store all notifications but show only unread in the dropdown
        this.notifications = response.data;
        this.unreadCount = this.unreadNotifications.length;
      } catch (error) {
        console.error("Failed to fetch notifications:", error);
      }
    },
    async handleNotificationClick(notification) {
      await this.markAsRead(notification.id);

      // Update unread count and navigate to full notifications page
      this.unreadCount = this.unreadNotifications.length;
      this.$router.push("/app/notifications");
    },
    async markAsRead(notificationId) {
      try {
        const token = localStorage.getItem("token");
        await axios.post(`https://apidash.smartpress.kz/api/notifications/${notificationId}/read`, {}, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const notification = this.notifications.find((n) => n.id === notificationId);
        if (notification) notification.read_at = new Date().toISOString();
      } catch (error) {
        console.error(`Failed to mark notification ${notificationId} as read:`, error);
      }
    },
    formatTimeAgo(timestamp) {
      const { t } = useI18n(); // Access the translation function
      const now = new Date();
      const notificationDate = new Date(timestamp);
      const minutesAgo = Math.floor((now - notificationDate) / 60000);

      if (minutesAgo < 60) {
        return t('time.minutesAgo', { count: minutesAgo });
      } else {
        const hoursAgo = Math.floor(minutesAgo / 60);
        return t('time.hoursAgo', { count: hoursAgo });
      }
    },
    getMessageByLocale(notificationData) {
      const { locale } = useI18n(); // Access current locale
      switch (locale.value) {
        case "ru":
          return notificationData.message_ru;
        case "kz":
          return notificationData.message_kz;
        default:
          return notificationData.message_en;
      }
    },
  },
};
</script>
